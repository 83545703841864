<script lang="ts" setup>
const { t } = useT();
type TComment = {
	name: string;
	location: string;
	text: string;
	logo: number;
};
const commentsList: TComment[] = [
	{
		name: t("Shannon C"),
		location: t("Detroit, MI"),
		text: t(
			"TAOFortune really knows how to make their players feel valued with their generous bonuses and promotions. It’s like a little treat for yourself!"
		),
		logo: 1
	},
	{
		name: t("Joe L"),
		location: t("Port St Joe, FL"),
		text: t(
			"Fun games - I thought I couldn’t be surprised any more but it managed to, really fun in-game features, and new games appear all the time."
		),
		logo: 2
	},
	{
		name: t("Hector S"),
		location: t("Baltimore, MD"),
		text: t(
			"I’ve been playing TaoFortune forever, I think, maybe more! Anyways, it’s genuinely the best site I’ve tried yet. The games are so pretty! The wins were frequent, which is amazing."
		),
		logo: 3
	},
	{
		name: t("Fausto A"),
		location: t("Schaumburg, IL"),
		text: t(
			"I like to browse the site because there’s always a new bonus or activity there. Support lady responded immediately and was very cordial about all my requests."
		),
		logo: 1
	}
];
</script>

<template>
	<MSliderWrapper
		class="wrapper"
		isComments
		navigation
		:list="commentsList"
		title="What you say about us matters"
		:spacing="20"
	>
		<template #default="{ item: comment }">
			<div class="comments-list__item swiper-slide">
				<div class="d-flex gap-3 mb-3">
					<NuxtImg :src="`/nuxt-img/comments/profile-${comment.logo}.jpg`" :alt="comment.name" width="64" height="64" />
					<div class="d-flex flex-column justify-content-center">
						<AText type="base" class="comments-list__name" :modifiers="['bold']">{{ comment.name }}</AText>
						<AText type="small" class="comments-list__location">{{ comment.location }}</AText>
					</div>
				</div>
				<AText type="base" class="comments-list__text">{{ comment.text }}</AText>
			</div>
		</template>
	</MSliderWrapper>
</template>

<style scoped lang="scss">
.swiper-slide {
	width: 327px;

	@include media-breakpoint-down(sm) {
		width: 300px;
		max-width: calc(100vw - 64px);
	}
}

.comments {
	&-list {
		&__item {
			padding: 24px;
			height: 234px;
			background: var(--gray-800);
			color: var(--neutral);
			border-radius: 16px;
			position: relative;

			img {
				border-radius: 10px;
			}
		}

		&__name {
			color: var(--neutral);
		}

		&__location {
			color: var(--gray-200);
			margin-top: 2px;
		}

		&__text {
			-webkit-line-clamp: 5;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
</style>
